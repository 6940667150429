const gallery = new Swiper('.gallery-slider', {
    loop: true,
    slidesPerView: 1,
    slidesPerColumn: 1,
    spaceBetween: 20,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    breakpoints: {
        1236: {
            slidesPerView: 4
        },
        1044: {
            slidesPerView: 3
        },
        496: {
            slidesPerView: 2
        }
    }
});