const usefulLinks = new Swiper('.useful-links-slider', {
    loop: true,
    slidesPerView: 1,
    slidesPerColumn: 1,
    spaceBetween: 15,
    // autoplay: {
    //     delay: 3000,
    // },
    breakpoints: {
        1375: {
            slidesPerView: 5
        },
        1044: {
            slidesPerView: 4,
            spaceBetween: 20
        },
        692: {
            slidesPerView: 3,
        },
        496: {
            slidesPerView: 2,
        }
    }
});