const swiper = new Swiper('.offer-slider', {
    // Optional parameters
    loop: true,
    slidesPerView: 1,

    pagination: {
        el: '.swiper-pagination',
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});